import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector'

export const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 56, 137]
});
export const walletconnect = new WalletConnectConnector({
    rpcUrl: `https://mainnet.infura.io/v3/e894560831664af78ad318068455eb15`,
    qrcode: true,
    pollingInterval: 15000
});
export function resetWalletConnector(connector) {
    if (connector && connector instanceof WalletConnectConnector) {
        connector.walletConnectProvider = undefined;
    }
}
export const walletlink = new WalletLinkConnector({
    url: `https://mainnet.infura.io/v3/e894560831664af78ad318068455eb15`,
    appName: "Cafrodytes",
    supportedChainIds: [1, 3, 4, 5, 56, 137],
});

// export const fm = new Fortmatic('pk_live_6F2CE98EC9773879');

