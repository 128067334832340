import Mint from "./pages/mint-page/Mint";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import MyCafrodytes from "./pages/my-cafrodytes/MyCafrodytes";
import React from "react";
function App() {
  return (
    <>
      <BrowserRouter >
        <Routes>
          <Route path='/' element={<Mint />} />
          <Route exact path='/my-cafrodytes' element={<MyCafrodytes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

