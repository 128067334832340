// export const CAFRODYTES_CONTRACT = '0xe61163cf4e65b04ba57d96c4e01b7223ae5b4ec1';
export const CAFRODYTES_CONTRACT = '0xfca218d046ffce2531763a8dd9098342ec771b0a';

export const cafrodytesABI = [
    { "inputs": [], "stateMutability": "nonpayable", "type": "constructor" }
    , {
        "anonymous": false, "inputs": [
            { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "approved", "type": "address" },
            { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "Approval", "type": "event"
    },
    {
        "anonymous": false, "inputs":
            [{ "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "operator", "type": "address" },
            { "indexed": false, "internalType": "bool", "name": "approved", "type": "bool" }]
        , "name": "ApprovalForAll", "type": "event"
    },
    {
        "anonymous": false, "inputs": [
            { "indexed": true, "internalType": "address", "name": "previousOwner", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "newOwner", "type": "address" }],
        "name": "OwnershipTransferred", "type": "event"
    },
    {
        "anonymous": false, "inputs": [
            { "indexed": true, "internalType": "address", "name": "from", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "to", "type": "address" },
            { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" }]
        , "name": "Transfer", "type": "event"
    },
    {
        "inputs": [], "name": "OWNER", "outputs": [
            { "internalType": "address payable", "name": "", "type": "address" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
        "name": "addressMintedBalance", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "to", "type": "address" },
            { "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "approve", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "approveMe", "type": "address" },
        { "internalType": "uint256", "name": "nftCost", "type": "uint256" }],
        "name": "approveToken", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }],
        "name": "balanceOf", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "baseExtension", "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "cost", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "bytes", "name": "a", "type": "bytes" }],
        "name": "decrypt", "outputs": [{ "internalType": "address", "name": "", "type": "address" },
        { "internalType": "uint256", "name": "", "type": "uint256" },
        { "internalType": "string", "name": "", "type": "string" },
        { "internalType": "string", "name": "", "type": "string" },
        { "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "name": "dnaRevealed", "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "a", "type": "address" },
        { "internalType": "uint256", "name": "b", "type": "uint256" },
        { "internalType": "string", "name": "c", "type": "string" },
        { "internalType": "string", "name": "d", "type": "string" },
        { "internalType": "string", "name": "e", "type": "string" }],
        "name": "encrypt", "outputs": [{ "internalType": "bytes", "name": "", "type": "bytes" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "getApproved", "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "getBaseURI", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "getContractBalance", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "getCurrentCost", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "owner", "type": "address" },
        { "internalType": "address", "name": "operator", "type": "address" }],
        "name": "isApprovedForAll", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "maxMintAmount", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "maxMintAmountPresale", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "maxSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_mintAmount", "type": "uint256" }],
        "name": "mint", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_mintAmount", "type": "uint256" }],
        "name": "mintToOwner", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [], "name": "name", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "notRevealedUri", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "owner", "outputs": [
            { "internalType": "address", "name": "", "type": "address" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [
            { "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "ownerOf", "outputs": [
            { "internalType": "address", "name": "", "type": "address" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [
            { "internalType": "bool", "name": "_state", "type": "bool" }],
        "name": "pause", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [], "name": "paused", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "preSaleCost", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "preSaleDate", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "preSaleEndDate", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "publicSaleDate", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    { "inputs": [], "name": "renounceOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
    {
        "inputs": [
            { "internalType": "string", "name": "dna", "type": "string" },
            { "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "reveal", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "name": "revealed", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "from", "type": "address" },
            { "internalType": "address", "name": "to", "type": "address" },
            { "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "safeTransferFrom", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "from", "type": "address" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "tokenId", "type": "uint256" },
        { "internalType": "bytes", "name": "_data", "type": "bytes" }],
        "name": "safeTransferFrom", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "operator", "type": "address" },
        { "internalType": "bool", "name": "approved", "type": "bool" }],
        "name": "setApprovalForAll", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "string", "name": "_newBaseExtension", "type": "string" }],
        "name": "setBaseExtension", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "string", "name": "_newBaseURI", "type": "string" }],
        "name": "setBaseURI", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_newCost", "type": "uint256" }],
        "name": "setCost", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_maxSupply", "type": "uint256" }],
        "name": "setMaxSupply", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "string", "name": "_notRevealedURI", "type": "string" }],
        "name": "setNotRevealedURI", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_preSaleDate", "type": "uint256" }],
        "name": "setPreSaleDate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_preSaleEndDate", "type": "uint256" }],
        "name": "setPreSaleEndDate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_newCost", "type": "uint256" }],
        "name": "setPresaleCost", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_publicSaleDate", "type": "uint256" }],
        "name": "setPublicSaleDate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_newmaxMintAmount", "type": "uint256" }],
        "name": "setmaxMintAmount", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "_newmaxMintAmount", "type": "uint256" }],
        "name": "setmaxMintAmountPreSale", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" }],
        "name": "supportsInterface", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "symbol", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "timestamp", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [
            { "internalType": "uint256", "name": "index", "type": "uint256" }],
        "name": "tokenByIndex", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [
            { "internalType": "address", "name": "owner", "type": "address" },
            { "internalType": "uint256", "name": "index", "type": "uint256" }],
        "name": "tokenOfOwnerByIndex", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "tokenURI", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [], "name": "totalSupply", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "stateMutability": "view", "type": "function"
    }, {
        "inputs": [
            { "internalType": "address", "name": "from", "type": "address" },
            { "internalType": "address", "name": "to", "type": "address" },
            { "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
        "name": "transferFrom", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "newOwner", "type": "address" }],
        "name": "transferOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    },
    {
        "inputs": [{ "internalType": "address", "name": "_owner", "type": "address" }],
        "name": "walletOfOwner", "outputs": [
            { "internalType": "uint256[]", "name": "", "type": "uint256[]" }],
        "stateMutability": "view", "type": "function"
    },
    {
        "inputs": [{ "internalType": "bytes", "name": "a", "type": "bytes" }],
        "name": "withdrawToken", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }];

export const wethABI = [
    { "inputs": [], "payable": false, "stateMutability": "nonpayable", "type": "constructor" },
    {
        "anonymous": false, "inputs": [
            { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "spender", "type": "address" },
            { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }],
        "name": "Approval", "type": "event"
    },
    {
        "anonymous": false, "inputs": [
            { "indexed": true, "internalType": "address", "name": "previousOwner", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "newOwner", "type": "address" }],
        "name": "OwnershipTransferred", "type": "event"
    },
    {
        "anonymous": false, "inputs": [
            { "indexed": true, "internalType": "address", "name": "from", "type": "address" },
            { "indexed": true, "internalType": "address", "name": "to", "type": "address" },
            { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }],
        "name": "Transfer", "type": "event"
    },
    {
        "constant": true, "inputs": [], "name": "_decimals", "outputs": [
            { "internalType": "uint8", "name": "", "type": "uint8" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "_name", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "_symbol", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": true, "inputs": [
            { "internalType": "address", "name": "owner", "type": "address" },
            { "internalType": "address", "name": "spender", "type": "address" }],
        "name": "allowance", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "address", "name": "spender", "type": "address" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "approve", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": true, "inputs": [
            { "internalType": "address", "name": "account", "type": "address" }],
        "name": "balanceOf", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "burn", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "decimals", "outputs": [
            { "internalType": "uint8", "name": "", "type": "uint8" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "address", "name": "spender", "type": "address" },
            { "internalType": "uint256", "name": "subtractedValue", "type": "uint256" }],
        "name": "decreaseAllowance", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "getOwner", "outputs": [
            { "internalType": "address", "name": "", "type": "address" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": false, "inputs": [{ "internalType": "address", "name": "spender", "type": "address" },
        { "internalType": "uint256", "name": "addedValue", "type": "uint256" }],
        "name": "increaseAllowance", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "mint", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "name", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "owner", "outputs": [
            { "internalType": "address", "name": "", "type": "address" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    { "constant": false, "inputs": [], "name": "renounceOwnership", "outputs": [], "payable": false, "stateMutability": "nonpayable", "type": "function" },
    {
        "constant": true, "inputs": [], "name": "symbol", "outputs": [
            { "internalType": "string", "name": "", "type": "string" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": true, "inputs": [], "name": "totalSupply", "outputs": [
            { "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false, "stateMutability": "view", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "address", "name": "recipient", "type": "address" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "transfer", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "address", "name": "sender", "type": "address" },
            { "internalType": "address", "name": "recipient", "type": "address" },
            { "internalType": "uint256", "name": "amount", "type": "uint256" }],
        "name": "transferFrom", "outputs": [
            { "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false, "stateMutability": "nonpayable", "type": "function"
    },
    {
        "constant": false, "inputs": [
            { "internalType": "address", "name": "newOwner", "type": "address" }],
        "name": "transferOwnership", "outputs": [], "payable": false, "stateMutability": "nonpayable", "type": "function"
    }];

export const WETH_CONTRACT = '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619';