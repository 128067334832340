import React, { useState, useEffect } from 'react';
import './mint.css'
import Web3 from 'web3';
import insta from '../../media/elements/n_insta_color.svg'
import twitter from '../../media/elements/n_twitter.svg'
import n_openSea from '../../media/elements/n_opensea.svg'
import discord from '../../media/elements/dicrd.svg'
import metamaskwlt from '../../media/elements/metamask.png'
import coinbasewlt from '../../media/elements/coinbaseWalletIcon.svg'
import walletconnectwlt from '../../media/elements/walletConnectIcon.svg'
import phisCaf from '../../media/elements/phis_caf.png'
import logo from '../../media/logo.png'
import copy from "copy-to-clipboard";
import check from '../../media/elements/check.png'
import toast, { Toaster } from 'react-hot-toast';
// import Timer from '../../component/Timer';
import { Link } from "react-router-dom";

// import fortmaticwlt from '../../media/elements/Fortmatic.svg'

import { useWeb3React } from '@web3-react/core';
// import spinner from '../../media/elements/spinner.gif'
import spin from '../../media/elements/spin2.gif'
// import { networkParams } from "../../helpers/networks";
import { truncateAddress } from "../../helpers/utils";
import { injected, walletconnect, resetWalletConnector, walletlink } from '../../helpers/connectors'
import { cafrodytesABI, CAFRODYTES_CONTRACT, wethABI, WETH_CONTRACT } from '../../config';

export const ListItem = ({ connectWlt, imgsrc, text }) => {
    return (
        <div>
            <div >
                <button onClick={connectWlt} className='buttons listitem'>
                    {/* <div className='connected'></div> */}
                    <p>{text}</p>
                    <img src={imgsrc} alt='logo' />
                </button>
            </div>
        </div>
    )
}

export default function Mint() {

    const [mintNumber, setMintNumber] = useState(1);
    const [isConnected, setIsConnected] = useState("CONNECT WALLET");
    const [showModal, setShowModal] = useState(false);
    const [isOnMatic, setIsOnMatic] = useState(true);
    // const [isOndifferentN, setIsOnDifferentN] = useState(false);

    const [switchButton, setSwitchButton] = useState(false)
    const [changeWallet, setChangeWallet] = useState(false)
    const [showPhisWarning, setShowPhishWarning] = useState(true)
    const [isCopying, setIsCopying] = useState(true)
    const [approved, setApproved] = useState(false)
    const [isMintStarted, setIsMintStarted] = useState(false);
    const [transactionHashCode, settransactionHashCode] = useState('https://polygonscan.com/tx/');
    const [cafroCost, setCafroCost] = useState(0);
    const [polygonExplorer, setPolygonExplorer] = useState("")
    const [wethExplorer, setwethExplorer] = useState("")
    const [walletBalance, setwalletBalance] = useState("")
    const [inSufficientFund, setinSufficientFund] = useState(false)
    const [isMintClicked, setIsMintClicked] = useState(false)
    const [isApprovedClicked, setisApprovedClicked] = useState(false)
    const [totalSupplyAvail, setTotalSupply] = useState(0)
    const web3reactContext = useWeb3React();

    var web3 = new Web3(window.ethereum);

    const Cafrodytes = new web3.eth.Contract(
        cafrodytesABI, CAFRODYTES_CONTRACT
    )

    const Weth = new web3.eth.Contract(
        wethABI,
        WETH_CONTRACT
    );

    const increseMintNumber = () => {
        if (mintNumber < 10) {
            setMintNumber(mintNumber + 1)
        }
        else {
            setMintNumber(10)
        }
    }
    const decreseMintNumber = () => {
        if (mintNumber > 1) {
            setMintNumber(mintNumber - 1)
        }
        else {
            setMintNumber(1)
        }
    }


    const checkInfoSimple = async () => {
        try {
            if (localStorage.getItem("WalletAddress")) {
                if (web3reactContext.account && localStorage.getItem("WalletAddress") !== web3reactContext.account) {

                    localStorage.setItem("WalletAddress", web3reactContext.account);
                }
                setIsConnected(truncateAddress(localStorage.getItem("WalletAddress")))
                setPolygonExplorer(`https://polygonscan.com/address/${localStorage.getItem("WalletAddress")}`)
                // setInventoryLink(`https://polygonscan.com/token/${CAFRODYTES_CONTRACT}?a=${localStorage.getItem("WalletAddress")}#inventory`)
                setwethExplorer(`https://polygonscan.com/token/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619?a=${localStorage.getItem("WalletAddress")}`)
            }
            else {
                if (web3reactContext.chainId) {
                    if (web3reactContext.chainId === 137) {
                        localStorage.setItem("WalletAddress", web3reactContext.account)
                        setIsOnMatic(true);
                        setIsConnected(truncateAddress(localStorage.getItem("WalletAddress")))
                        setSwitchButton(false);
                    }
                    else {
                        // console.log(web3reactContext.connector === injected)
                        setShowModal(true);
                        setIsOnMatic(false);
                        setSwitchButton(true);
                        setIsConnected(truncateAddress(localStorage.getItem("WalletAddress")))
                    }
                }
            }
        } catch (ex) {
            console.log(ex);

        }
    };

    const showModalFun = () => {
        setShowModal(true)
        if (localStorage.getItem("WalletAddress")) {
            setChangeWallet(true)
        }


    }
    const connectMetamask = async () => {
        try {
            // localStorage.clear()
            await web3reactContext.activate(injected);
            localStorage.setItem('WalletName', 'Metamask')
            setShowModal(false)
        } catch (ex) {
            console.log(ex);
        }
    };

    const connectWalletConnect = async () => {
        try {
            resetWalletConnector(walletconnect);
            await web3reactContext.activate(walletconnect);
            localStorage.setItem('WalletName', 'Wallet Connect')
            setShowModal(false)

        } catch (ex) {
            console.log(ex);
        }
    };
    const connectCoinbase = async () => {
        try {
            await web3reactContext.activate(walletlink);
            localStorage.setItem('WalletName', 'Coinbase')
            setShowModal(false)
        } catch (ex) {
            console.log(ex);
        }
    };
    // smart contract initiation
    const checkPresaleDate = async () => {
        try {
            // console.log(web3.providers)
            const preSaleDate = await Cafrodytes.methods.preSaleDate().call();
            const totalSupply = await Cafrodytes.methods.totalSupply().call();
            setTotalSupply(totalSupply - 9500)
            const currentCost = await Cafrodytes.methods.getCurrentCost().call();

            const currentDate = new Date();

            const d_preSaleDate = new Date(preSaleDate * 1000);
            // const options = { month: 'short', day: 'numeric', year: 'numeric' }
            // setSaleDate(d_preSaleDate.toLocaleDateString('en-US', options));
            if (currentDate < d_preSaleDate) {
                setIsMintStarted(false)
                setCafroCost(currentCost / (10 ** 18))
            }
            else {
                setIsMintStarted(true)
                setCafroCost(currentCost / (10 ** 18))
            }
        }
        catch (err) {
            console.log(err)
            localStorage.removeItem("WalletAddress")
            setIsConnected("CONNECT WALLET")
            setIsOnMatic(false);
            setChangeWallet(false)
            setwalletBalance(false)
        }
    }
    const approveTrans = async () => {
        try {
            setisApprovedClicked(true)
            if (localStorage.getItem("WalletAddress")) {
                const approve = Weth.methods.approve(CAFRODYTES_CONTRACT, '115792089237316195423570985008687907853269984665640564039457584007913129639935');
                const gasLimit = await approve.estimateGas({ from: localStorage.getItem("WalletAddress") });
                const ethApproved = await approve.send({ from: localStorage.getItem("WalletAddress"), gas: gasLimit });
                // console.log(ethApproved.transactionHash)
                let thl = ethApproved.transactionHash
                setApproved(true);
                setisApprovedClicked(false)
                toast.success(() => (
                    <span>
                        Successfully Approved!
                        <div>
                            <a className='th_link' href={transactionHashCode + thl} target="_blank" rel="nopener noreferrer">
                                See transaction
                            </a>
                        </div>
                    </span>
                ),
                    {
                        style: {
                            duration: 8000,
                            position: 'top-right',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        }
                    }
                );
            }
            else {
                toast('Please connect your wallet first! ', {
                    style: {
                        duration: 4000,
                        position: 'top-center',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                })
                setisApprovedClicked(false)
            }
        }
        catch (err) {
            console.log(err)
            setisApprovedClicked(false)
        }

    }
    const checkallowance = async () => {
        try {
            const allowance = await Weth.methods.allowance(localStorage.getItem("WalletAddress"), CAFRODYTES_CONTRACT).call();
            if (allowance > 0) {
                return true
            }
            return false
        }
        catch (err) {
            console.log(err)
            setIsOnMatic(false)


        }
    }

    const checkBalance = async (address) => {
        const balance = await Weth.methods.balanceOf(address).call();
        return balance;
    }

    const processMinting = async () => {
        try {
            setIsMintClicked(true);
            const allowance = checkallowance();
            if (allowance) {

                const mint = Cafrodytes.methods.mint(mintNumber);
                const gasLimit = await mint.estimateGas({
                    from: localStorage.getItem("WalletAddress")
                });
                const mintObject = await mint.send({
                    from: localStorage.getItem("WalletAddress"),
                    gas: gasLimit
                });
                let transactionHashLink = await mintObject.transactionHash
                settransactionHashCode(`https://polygonscan.com/tx/${transactionHashLink}`)
                // console.log(transactionHashCode + transactionHashLink);
                setIsMintClicked(false);
                toast.success(() => (
                    <span>
                        Successfully Minted!
                        <div>
                            <a className='th_link' href={transactionHashCode + transactionHashLink} target="_blank" rel="nopener noreferrer">
                                See transaction
                            </a>
                        </div>
                    </span>
                ),
                    {
                        style: {
                            duration: 8000,
                            position: 'top-right',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        }
                    }
                );
            }
        }
        catch (err) {
            console.log(err)
            setIsMintClicked(false);
        }
    }

    const haveAllowance = async () => {
        if (localStorage.getItem("WalletAddress")) {
            const allowance = await checkallowance();
            if (allowance) {

                setApproved(true)
            }
            else {
                setApproved(false)
            }
        }
    }

    useEffect(() => {
        checkInfoSimple();
        haveAllowance()
        checkPresaleDate()
        if (localStorage.getItem("WalletAddress")) {
            checkBalance(localStorage.getItem("WalletAddress")).then((balance => {
                let balance2 = balance / (10 ** 18)
                if (balance2 < (cafroCost * mintNumber)) {
                    setinSufficientFund(true)
                }
                else {
                    setinSufficientFund(false)
                }
            }))
            web3.eth.getBalance(localStorage.getItem("WalletAddress"))
                .then((balance) => {
                    let finalBalance = balance / (10 ** 18)
                    let fb = Math.floor(finalBalance * 100) / 100
                    setwalletBalance(`${fb} MATIC`)
                });
        }
    })

    const copyFun = () => {
        copy(localStorage.getItem("WalletAddress"))
        setIsCopying(false);
        setTimeout(() => {
            setIsCopying(true);
        }, 1000);
    }
    // const time = new Date(saleDate);

    return (
        <>
            <Toaster
                // position="top-right"
                reverseOrder={false}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 6000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    }
                }}
            />
            {/* {!isMintStarted ?
                <div className='mintTimer'>
                    <div className='mt_overlay'></div>
                    <div className='mt_text'>
                        {saleDate === "" ? <img className='mt_spinner' alt='loading' src={spinner} /> :
                            <><p className='mt_heading'>Presale Will Start In</p>
                                <Timer expiryTimestamp={time} />
                            </>}
                    </div>

                </div> :
                <></>} */}
            {isMintStarted ? <></> : <></>}
            {showPhisWarning ?
                <div class="phishing_warning">
                    <img class="phis_caf" src={phisCaf} alt="cafrodytes" />
                    <div class="p_warn">
                        <span>Phishing Warning: </span>
                        &nbsp;Please make sure you're visiting &nbsp;
                        <a href="https://www.cafrodytes.com/">https://www.cafrodytes.com</a>
                        &nbsp;-check the url carefully.
                    </div>
                    <div onClick={() => setShowPhishWarning(false)} class="c_phis">&#xd7; </div>
                </div> :
                <></>
            }

            <section className='minting_sec'>
                <nav className="mint_nav">
                    <div className="nc1_social">
                        <a href='https://opensea.io/collection/cafrodytes-official' rel="noopener noreferrer" target="_blank">
                            <img src={n_openSea} alt="open sea" />
                        </a>
                        <a href="https://twitter.com/cafrodytes" rel="noopener noreferrer" target="_blank">
                            <img src={twitter} alt="twitter" />
                        </a>
                        <a href="https://www.instagram.com/cafrodytesofficial/" rel="noopener noreferrer" target="_blank">
                            <img src={insta} alt="instagram" />
                        </a>
                    </div>
                    <div className='nvdiv2'>
                        {walletBalance ? <>
                            <div className='walletbalContainer'>
                                <div className='wbc_Bal'>{walletBalance}</div>
                                <div onClick={showModalFun} className='mb cnct_wlt details_btn wlt_address'>{isConnected}</div>
                            </div>
                        </> : <div onClick={showModalFun} className='mb cnct_wlt details_btn'>{isConnected}</div>}
                        <div className="nc2_social">
                            <a href="https://discord.com/invite/rah9HWrKHc" rel="noopener noreferrer" target="_blank" className="nc2_social">
                                <img src={discord} alt="discord" />
                                Join Our Discord
                            </a>
                        </div>
                    </div>
                </nav>
                <div className="header_logo">
                    <a href="https://www.cafrodytes.com/">
                        <img src={logo} alt="" />
                    </a>

                </div>

                <div className="mint_block_container">
                    <div className="mint_block">
                        <div className="mint_heading">Mint your Cafrodyte!</div>

                        <div className='mint_card'>
                            <p className='mh2'>Choose the number of cafrodytes you want to mint</p>
                            <div className='mns'>
                                <p>{totalSupplyAvail}/500</p>
                                <p className='mns_p2'>Minted</p>
                            </div>
                            {!approved ?
                                <>
                                    {isApprovedClicked ?
                                        <>
                                            <button className='mb_mint lm_btn' ><img style={{ width: "20px" }} src={spin} alt='spinner' /></button>
                                        </> : <button onClick={approveTrans} className='mb_mint' >Approve WETH</button>
                                    }
                                </> :
                                <div className='mbc_container'>
                                    <div className='mic'>
                                        <div onClick={decreseMintNumber} className='micb'>- </div>
                                        {/* <input type='text' inputMode="numeric" defaultValue='1' value={mintNumber} /> */}
                                        <div className='input'>{mintNumber}</div>
                                        <div onClick={increseMintNumber} className='micb'>+</div>
                                    </div>
                                    {inSufficientFund ? <div className='insuff_div'>
                                        <div>Insufficient Funds!</div>
                                        <a href='https://quickswap.exchange/#/swap?outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619' target="_blank" rel="noopener noreferrer">Buy WETH</a>
                                    </div> :
                                        <>
                                            {isMintClicked ?
                                                <>
                                                    <button className='mb_mint lm_btn' ><img style={{ width: "20px" }} src={spin} alt='spinner' /></button>
                                                </> : <button onClick={processMinting} className='mb_mint' >Mint!</button>
                                            }
                                        </>
                                    }
                                    <p>{(cafroCost * mintNumber).toFixed(4)} &nbsp;<a className='weth_link' href={wethExplorer} target="_blank" rel="noopener noreferrer">WETH</a></p>
                                </div>}
                            <p className='mhh3'>
                                The maximum number of Cafrodytes <br />
                                that you can mint at a time is 10
                            </p>
                            <p className='mhh4'>
                                We will ask you to connect your wallet in order to make the purchase, Please
                                note that we are not minting Cafrodytes from anywhere else other than this
                                website and Opensea. Do not link your wallet to any other website claiming to sell
                                Cafrodytes
                            </p>
                        </div>

                    </div>

                    {showModal ?
                        <>
                            <div onClick={() => setShowModal(false)} className='modal'></div>
                            <div className='modalContainer'>
                                <div className='upperdiv'>
                                    {!switchButton ?
                                        <button onClick={() => setShowModal(false)} className='closebtn buttons'>&#xd7;</button>
                                        : <></>}
                                </div>
                                {!changeWallet ?
                                    <>
                                        {isOnMatic ?
                                            <>
                                                <ListItem connectWlt={connectMetamask} imgsrc={metamaskwlt} text={"Metamask"} />
                                                <ListItem connectWlt={connectWalletConnect} imgsrc={walletconnectwlt} text={"WalletConnect"} />
                                                <ListItem connectWlt={connectCoinbase} imgsrc={coinbasewlt} text={"Coinbase Wallet"} />
                                            </> :
                                            <>
                                                <div className='warning_Text'>It seems either you don't have any wallet or you are currently on a different network, please switch to <b>Polygon Network</b> and refresh the page!
                                                    <p style={{ marginTop: "20px" }}><a href='https://metamask.io/download/' target="_blank" rel="noopener noreferrer">Click here to install Metamask</a></p>
                                                </div>
                                            </>}

                                        <div className='learnDiv'>
                                            New to Matic?
                                            &nbsp;<a target='_blank' rel="noreferrer noopener" href='https://docs.polygon.technology/'>Learn more about wallets</a>
                                        </div>
                                    </> :
                                    <>
                                        <div className='wc_container'>
                                            <div className='wc_us'>
                                                <div className='wc_wt'>Connected With {localStorage.getItem('WalletName')}</div>
                                                <button onClick={() => setChangeWallet(false)} >Change</button>
                                            </div>
                                            <div className='wc_wa'>
                                                {truncateAddress(localStorage.getItem("WalletAddress"))}
                                            </div>
                                            <div className='wc_links'>
                                                {isCopying ?
                                                    <div onClick={copyFun}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                                        &nbsp; Copy Address
                                                    </div> :
                                                    <>
                                                        <div>
                                                            <img src={check} alt='copied' />
                                                            &nbsp; Copied!
                                                        </div>
                                                    </>
                                                }
                                                <a href={polygonExplorer} target="_blank" rel="noopener noreferrer" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                                    &nbsp;View on Explorer
                                                </a>
                                            </div>
                                        </div>
                                        <Link to='/my-cafrodytes' className='viewCafro_btn'>View My Cafrodytes</Link>
                                    </>
                                }
                            </div>
                        </> :
                        <></>
                    }
                </div>
                <div id="galaxy">
                    <div className="stars-back"></div>
                    <div className="stars-middle"></div>
                    <div className="stars-front"></div>
                    <div className="bg center"></div>
                </div>
            </section>


        </>

    )
}
