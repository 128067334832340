import React, { useEffect, useState } from 'react'
import './myCaf.css'
import insta from '../../media/elements/n_insta_color.svg'
import twitter from '../../media/elements/n_twitter.svg'
import n_openSea from '../../media/elements/n_opensea.svg'
import discord from '../../media/elements/dicrd.svg'
import bluePlanet from '../../media/elements/Blue_Planet.png'
import redPlanet from '../../media/elements/m-p.png'
import whitePlanet from '../../media/elements/White_Planet.png'
import platform from '../../media/elements/platform.png'
import logo from '../../media/logo.png'
import { CAFRODYTES_CONTRACT } from '../../config'
import spinner from '../../media/elements/spinner.gif'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



export const SliderItem = ({ imgsrc, id, cls }) => {

    return (

        <>
            <div className={cls === 1 || cls % 3 === 1 ? "upr_cls" : ""}>
                <div className="plat_container">
                    <div className='pltfrm_buy'>#{id}</div>
                    <div className='pltfrm_cont'>
                        <img className='slider_platform' src={imgsrc} alt='No Cafro' />
                    </div>

                    <div className='slider_btn_container'>
                        <a href='https://opensea.io/collection/cafrodytes-official' target='_blank' rel="noopener noreferrer" className='slider_sell_btn slider_btn'>Sell</a>
                        <a href='https://www.cafrodytes.com/coming-soon.html' target='_blank' rel="noopener noreferrer" className='slider_play_btn slider_btn'>Play</a>

                    </div>
                </div>
            </div>
        </>
    )
}

function MyCafrodytes() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [ownCafrodytes, setownCafrodytes] = useState(false)
    const [dataLoaded, setdataLoaded] = useState(false)
    const [nftData, setnftData] = useState()


    const getOwnNftData = async () => {
        let OWNER_ADDRESS = await localStorage.getItem("WalletAddress")
        let res = await fetch(`https://polygon-mainnet.g.alchemy.com/nft/v2/-CD0iqBqUGe3Gm1kxyhX_t8RwK8o1et6/getNFTs/?owner=${OWNER_ADDRESS}&contractAddresses[]=${CAFRODYTES_CONTRACT}`)
        let data = await res.json();
        setdataLoaded(true)
        // console.log(data.ownedNfts)
        // console.log(data.ownedNfts.length)
        if (data.ownedNfts.length > 0) {
            setownCafrodytes(true)
            setnftData(data.ownedNfts)
        }
    }

    useEffect(() => {
        // console.log(localStorage.getItem("WalletAddress"))
        getOwnNftData()
    }, [])

    return (
        <section className='my_caf_sec'>
            <nav className="mint_nav mint_nav2">
                <div className="nc1_social nc1_social2">
                    <a href='https://opensea.io/collection/cafrodytes-official' rel="noopener noreferrer" target="_blank">
                        <img src={n_openSea} alt="open sea" />
                    </a>
                    <a href="https://twitter.com/cafrodytes" rel="noopener noreferrer" target="_blank">
                        <img src={twitter} alt="twitter" />
                    </a>
                    <a href="https://www.instagram.com/cafrodytesofficial/" rel="noopener noreferrer" target="_blank">
                        <img src={insta} alt="instagram" />
                    </a>
                </div>
                <div className='nvdiv2'>
                    <div className="nc2_social">
                        <a href="https://discord.com/invite/rah9HWrKHc" rel="noopener noreferrer" target="_blank" className="nc2_social">
                            <img src={discord} alt="discord" />
                            Join Our Discord
                        </a>
                    </div>
                </div>
            </nav>
            <div className="header_logo">
                <a href="https://www.cafrodytes.com/">
                    <img src={logo} alt="" />
                </a>
            </div>
            {dataLoaded ?
                <>
                    <div className='main-container'>
                        <div className='head-my_caf luckyguy'>My Cafrodytes</div>
                        {ownCafrodytes ?
                            <>
                                {/* <div className='swiper'>
                                    <div onClick={() => scroll(-200)} className='swiper-button'>&#706;</div>
                                    <div className='swiper_wrapper' ref={ref}>
                                        {nftData.map((item) => {
                                            let id = item.id.tokenId
                                            console.log(parseInt(id, 16))
                                            return (
                                                <SliderItem imgsrc={item.media[0].gateway} id={parseInt(id, 16)} key={item.id.tokenId} />

                                            )
                                        })}
                                    </div>
                                    <div onClick={() => scroll(-200)} className='swiper-button'>&#707;</div>
                                </div> */}

                                <Slider className='slider' {...settings}>
                                    {nftData.map((item, index) => {
                                        let id = item.id.tokenId
                                        return (
                                            <SliderItem cls={index} imgsrc={item.media[0].gateway} id={parseInt(id, 16)} key={item.id.tokenId} />

                                        )
                                    })}
                                </Slider>

                            </>
                            :
                            <>
                                <div className='own_no_head'>No Cafrodytes Found !</div>
                                <div className='own_no_head2'>Get yourself a Cafrodyte to play !</div>
                                <div className='plat_container'>
                                    <a href='https://opensea.io/collection/cafrodytes-official' rel="noopener noreferrer" target="_blank" className='pltfrm_buy'>Buy</a>
                                    <img className='platform' src={platform} alt='No Cafro' />
                                </div>
                            </>
                        }

                    </div>
                </> :
                <>
                    <div className='overlay_myCaf'>
                        <img src={spinner} alt='spinner' />
                    </div>

                </>}

            <div className='planetContainer'>
                <img className='plnt blue_planet' src={bluePlanet} alt='planet' />
                <img className='plnt red_planet' src={redPlanet} alt='planet' />
                <img className='plnt white_planet' src={whitePlanet} alt='planet' />
            </div>
            <div id="galaxy">
                <div className="stars-back"></div>
                <div className="stars-middle"></div>
                <div className="stars-front"></div>
                <div className="bg center"></div>
            </div>

        </section>
    )
}

export default MyCafrodytes
